import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { menuData } from "../data/MenuData"

const AppHeader = () => {

  const [nav, showNav] = React.useState(false)

  return (
    <Nav>
      {/* link LOGO à gauche */}
      <NavLink to="/"><span> </span></NavLink>
      {/* burger à droite */}
          <MenuIcon nav={nav} onClick={() => showNav(!nav)}>
            <div />
            <div />
            <div />
          </MenuIcon>
        {/* Le menu classique */}
          <NavMenu nav={nav}>
                {/* "link" because MenuData link: */}
          { menuData.map((item, index) => (
              <NavLink to={item.link} key={index}>
                {item.title}
              </NavLink>
          ))}
          </NavMenu>
    </Nav>
  )
}

export default AppHeader

/* LES STYLES */
/* NavBar Header */
const Nav = styled.nav`
  height: 60px;
  display: flex;
  justify-content:space-between ;
  padding: 0.5rem calc((100vw-1300px) / 2);
  z-index: 100;
  position: relative;
  font-weight: bold;
    @media screen and (max-width: 768px) {
      height: 60px;
    
  `
/* liens de navigation Header -- npm gatsby-link */
const NavLink = styled(Link)`
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 2rem;
  height: 100%;
  cursor: pointer;
  font-size: 25px;
    span {
      font-weight: bold;
      @media screen and (max-width: 768px) {
        display:none;
      }
    }
`
/* menu header avec les routes dans l'url*/
const NavMenu = styled.div`
  display: flex;
  align-items: center;
  a {
    :hover {
      font-weight: bold;
      color: aqua;
      transition: color 300ms;
    }
  }
  @media screen and (max-width: 768px) {
        background-color: rgba(0,0,0, 0.9);
        display: flex;
        flex-direction: column;
        justify-content: left;
        height: 100vh;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 1;
        font-size:x-large;
        transform: ${({nav}) => (nav ? "translateX(0%)" : "translateX(-100%)")};
        transition: all 0.55s ease-out;
        position: fixed;
          font-size: 8px;
          letter-spacing: 0rem;
          text-align: center;
          a {
            color: white;
          }
         
}`
/* burger header pour Mobile -- npm react-icons */
const MenuIcon = styled.button`
  display: none;
  color: black;
  @media screen and (max-width: 768px) {
    position: fixed;
    top: 1rem;
    right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 1.5rem;
    height: 1.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 5;
    transition: opacity 300ms, transform 300ms;
      div {
        width: 1.5rem;
        height: 0.25rem;
        background: black;
        border-radius: 5px;
        transform-origin: 1px;
        position: relative;
        :first-child {
          transform: ${({nav}) => nav ? 'rotate(45deg)' : 'rotate(0deg)'};
        }
        :nth-child(2) {
          opacity :${({nav}) => nav ? "0" : "1"};
        }
        :nth-child(3) {
          transform: ${({nav}) => nav ? 'rotate(-45deg)' : 'rotate(0deg)'};
        }
      }  
  }
`
